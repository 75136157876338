<template>
  <div class="siteDisplayArea" tag="div" :list="nodes">
    <div v-for="node in nodes" :key="node.name" :style="{ 'margin-left': `${depth * 2}px` }" class="node  w3-animate-left">

      <span class="type w3-hover-text-green" @click="nodeClicked(node)" v-if="this.depth == 2">
        <i class="fa-thin fa-location-dot"></i>
      </span>
      
      <span class="type w3-hover-text-green" @click="nodeClicked(node)" v-else-if="this.depth == 3">
        <i class="fa-thin fa-building"></i>
      </span>

      <span class="type w3-hover-text-green" @click="nodeClicked(node)" v-else-if="this.depth == 4">
        <i class="fa-thin fa-house"></i>
      </span>

      <span class="type w3-hover-text-green" @click="nodeClicked(node)" v-else-if="this.depth == 5">

        <i v-if="node.attached_to.includes('Mini')" class="fa-solid fa-fire"></i>
        <i v-else-if="this.stateData.tstatQrs.some(str => node.qr.toString().includes(str))" class="fa-thin fa-thermometer-0"></i>
        <i v-else-if="node.attached_to.includes('Water') || this.stateData.waterQrs.includes(node.qr)" class="fa-thin fa-droplet"></i>
        <i v-else-if="node.attached_to.includes('Electric')" class="fa-thin fa-bolt"></i>
        <i v-else class="fa-thin fa-satellite-dish"></i>
      
      </span>


      <span class='w3-hover-text-green' @click="nodeClicked(node)" v-if="this.depth == 2">
        <!-- {{ this.depth }}-->
        {{ this.depth != 5 ? node.name : node.location + ' '+ node.attached_to }}
        <!--{{node.name}}-->
      </span>
      <span class="w3-hover-text-green" @click="nodeClicked(node)" v-else-if="this.depth != 2">
        <!-- {{ this.depth }}-->
        {{ this.depth != 5 ? node.name : node.location+' '+ node.attached_to }}
        <!--{{node.name}}-->
      </span>

      <!-- The device reporting status icons -->
      <span v-if="node.real_time_status == 'late'" class="status-dot w3-orange"> - </span>
      <span v-if="node.real_time_status == 'offline'" class=" status-dot w3-red"> - </span>
      <span v-else-if="node.real_time_status == 'unconfirmed'" class="status-dot w3-black ">-</span>

      <!-- The device GID not set icon -->
      <span v-if="node.gid_set_flag == 0" class="w3-text-red "> G </span>

      <!-- if the node is an exception -->
      <span v-if="node.exception_flag == 1" class="w3-text-red "> E </span>

      <!-- if the node is vacant -->
      <span v-if="node.vacant_flag == 1" class="w3-text-red "> V </span>

      <TreeBrowser v-if="isExpanded(node) && node.children" :nodes="node.children" :depth="this.depth + 1" />

    </div>

  </div>
</template>
  
<script>


import { store } from '../../helpers/state.js'



export default {
  name: "TreeBrowser",
  props: {
    nodes: Array,
    depth: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      expanded: [],
      stateData: store,
      base: process.env.VUE_APP_HOSTBASE,
      url: '',


    };
  },
  provide() {
    return {
      expanded: this.expanded
    };

  },


  methods: {

    isExpanded(node) {


      return this.expanded.indexOf(node) !== -1;

    },

    async checkOpenClosed(node) {
      if (!this.isExpanded(node)) {

        console.log('opened');
        this.expanded.push(node);
        this.stateData.expanded.push(node);

        this.expanded[this.expanded.indexOf(node)].children = await this.stateData.getChildren(this.url);


        //this.stateData.expandedDetails = this.stateData.expanded[this.stateData.expanded.indexOf(node)];

      } else {

        console.log('closed');
        //this.stateData.expandedDetails = this.stateData.expanded[this.stateData.expanded.indexOf(node)];
        this.expanded.splice(this.expanded.indexOf(node));

      }

    },

    async nodeClicked(node) {

      console.log('node clicked in the tree', node)
      this.stateData.depth = this.depth;

      switch (this.depth) {

        case 2:

          //properties

          // set all the global vars for the property components
          await this.stateData.nodeInfo(node, this.depth, 1);
          // set the site view type to property details
          this.stateData.siteViewType.siteType = 'propertyDetails';

          // add the children to the tree or remove them if they already exist
          this.url = `${this.base}/api/sites/${node.id}/structures?user_id=${this.stateData.commandConfig.user_id}&sk=${this.stateData.commandConfig.sk}`;
          this.checkOpenClosed(node);

          break;

        case 3:

          //buildings

          await this.stateData.nodeInfo(node, this.depth, 1);
          this.stateData.siteViewType.siteType = 'buildingDetails';



          // fetch the children (units) for the building that was clicked
          this.url = `${this.base}/api/sites/${node.property_id}/structures/${node.id}/units?user_id=${this.stateData.commandConfig.user_id}&sk=${this.stateData.commandConfig.sk}`;
          await this.checkOpenClosed(node);



          break;

        case 4:

          // units

          await this.stateData.nodeInfo(node, this.depth, 1);


          //updating the breadcrumbs path
          this.stateData.siteViewType.siteType = 'unitDetails';

          this.url = `${this.base}/api/sites/${node.property_id}/structures/${node.building_id}/units/${node.id}/devices?user_id=${this.stateData.commandConfig.user_id}&sk=${this.stateData.commandConfig.sk}`;
          await this.checkOpenClosed(node);



          break;

        case 5:

          // devices

          // set vars for this scope
          if (!this.isExpanded(node)) {
            this.stateData.expanded.push(node);

          }

          await this.stateData.nodeInfo(node, this.depth).then(() => {
            this.stateData.siteViewType.siteType = 'deviceDetails';
          });

          break;

        default:
          // Handle other cases here
          console.log('the default case was hit (property view)');
          this.stateData.siteViewType.siteType = 'propertyDetails';
          break;

      }

    },


  },

  created() {
    console.log('created the sitetree')
    console.log('local depth:', this.depth);
    if (this.depth == 2) {
      this.expanded.push(this.stateData.tree[0]);
      this.stateData.expanded.push(this.stateData.tree[0]);
      this.stateData.node = this.stateData.tree[0];
      this.stateData.expandedDetails = this.stateData.tree[0];

      this.stateData.nodeInfo(this.stateData.tree[0], this.depth, 1);
      this.stateData.isDivVisible = false;

    }
  }



}


</script>
  
<style scoped>
.node {
  text-align: left;
  font-size: 18px;
  cursor: pointer;
}
.status-dot {
    position: relative;
    top: 6px;
    margin-left: 1px;
    display: inline-block;
    height: 10px;
    width: 10px;
    background: #34ff4a;
    border-radius: 50%;
}
.type {
  margin-right: 10px;
}
</style>